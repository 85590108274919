import React, {useState} from 'react';
import get from 'lodash.get';
import {useDispatch, useSelector} from 'react-redux';
import {useApolloClient, useMutation} from '@apollo/client';
import {Tooltip} from '@material-ui/core';
import {Settings} from '@material-ui/icons';
import isEmpty from 'lodash.isempty';
import {useIntl} from 'react-intl';
import {selectVSFeatures} from '@components/Login/userSlice';
import {selectConfig} from '@components/appSlice';
import {isVsFeatureEnabled} from '@utils/helpers';
import {decodeSignatureDataFromBase64, getChecksumFromBase64} from '@components/Core/components/PerfectSignature/hooks/utils';
import SignatureSelectionModal from './SignatureSelectionModal/SignatureSelectionModal';
import {useCeremony} from '../../../../hooks/queries';
import useSignatureImage from '../../../../hooks/useSignatureImage';
import SIGN_APPROVAL from '../../utils/signApproval.gql';
import SIGN_APPROVAL_WITH_IMAGE from '../../utils/signApprovalWithImage.gql';
import {
    selectCachedHanddrawnSignature,
    selectCachedImageSignature,
    selectCachedSignatureData,
    selectDocumentFields,
    selectSelectedDocumentId,
    selectTransactionId,
    setAnimatedSignatureId,
    setCachedHanddrawnSignature,
    setCachedImageSignature
} from '../../../../signerUiSlice';
import {
    getSignatureErrorMessage,
    getSignatureErrors
} from '../../../utils';
import useJoinMessage from '../../../../../../hooks/useJoinMessage';
import {AppOutlinedIconButton} from '../../../../../Core/buttons/AppOutlinedIconButton/AppOutlinedIconButton';
import SignatureButton from '../SignatureButton/SignatureButton';
import {isFieldDisabled} from '../../../../utils/selectors';
import {CornerWidget} from '../../SigningField/CornerWidget/CornerWidget';
import {getFieldPositionStyles, updateCache} from '../../utils/fieldUtils';
import {SignatureSelectionModalV2} from './SignatureSelectionModalV2/SignatureSelectionModalV2';
import {SIGNATURE_V2_FEATURE} from '../../../../../../../constants';

const CaptureSignature = ({
    field,
    signatureMetaData,
    selectedField,
    selectField,
    unselectField,
    fieldsErrors,
    fieldsRef,
    setFieldDataToRef
}) => {
    const dispatch = useDispatch();
    const {
        approval,
        isCaptureSig,
        isMobileSig,
        hasConditions
    } = field;
    const client = useApolloClient();
    const {
        session,
        transaction: {
            cachedSignature: backendCachedHanddrawnSignature
        } = {},
        refetch: refetchCeremony
    } = useCeremony();
    const transactionId = useSelector(selectTransactionId);
    const documentId = useSelector(selectSelectedDocumentId);
    const joinMessage = useJoinMessage();
    const vsFeatures = useSelector(selectVSFeatures);
    const config = useSelector(selectConfig);
    const hasPerfectFreehandFeature = isVsFeatureEnabled({
        feature: {id: SIGNATURE_V2_FEATURE},
        vsFeatures,
        env: config.VS_ENV
    });
    const canUseImageToSign = field.approval.isFromFile || hasPerfectFreehandFeature;
    const {signatureImage: backendCachedImageSignature} = useSignatureImage({skip: !canUseImageToSign});
    const defaultHanddrawnSignature = get(session, 'user.signature.handdrawn', null);
    const [signApprovalWithImage, {loading: isSigningUsingImage, error: signingWithImageError}] = useMutation(SIGN_APPROVAL_WITH_IMAGE);
    const [signApproval, {loading: isSigningUsingSignature, error: signingWithApprovalError}] = useMutation(SIGN_APPROVAL);
    const [isOpenSignatureSelectionModal, setIsOpenSignatureSelectionModal] = useState(false);
    const fields = useSelector(selectDocumentFields);
    const isDisabled = isFieldDisabled(field, fields);
    const cachedHanddrawnSignature = useSelector(selectCachedHanddrawnSignature);
    const cachedImageSignature = useSelector(selectCachedImageSignature);
    const encodedSignatureData = session?.user?.signature?.handdrawn;
    const signatureData = decodeSignatureDataFromBase64(encodedSignatureData);
    const cachedSignatureData = useSelector(selectCachedSignatureData);

    const getPayloadToSign = (value) => ({
        update: updateCache({
            approvalId: approval.id,
            client,
            documentId,
            hasConditions,
            isCaptureSig,
            isFormValid: false,
            isMobileSig,
            transactionId,
            value
        }),
        variables: {
            documentId, originalId: approval.originalId, transactionId, value
        }
    });
    const validationErrors = getSignatureErrors({
        approvalId: approval.originalId,
        fieldsErrors
    });
    const intl = useIntl();
    const validationError = !isEmpty(validationErrors) ? validationErrors[0] : null;
    const error = signingWithImageError || signingWithApprovalError;
    const loading = isSigningUsingImage || isSigningUsingSignature;
    const errorMessage = getSignatureErrorMessage({
        intl, mutationError: error, validationError
    });
    const position = getFieldPositionStyles(field);

    async function signUsingImage(value, isFileUpload) {
        if (!isFileUpload) {
            await dispatch(setAnimatedSignatureId(approval.originalId));
        }
        dispatch(setCachedImageSignature(value));
        await signApprovalWithImage(getPayloadToSign({fromFileData: value}));
    }

    async function signUsingSignature(value) {
        dispatch(setCachedHanddrawnSignature(value));
        await signApproval(getPayloadToSign(value));
        refetchCeremony();
    }

    function openModalToChooseSignatureToSign() {
        setIsOpenSignatureSelectionModal(true);
    }

    const signOnClickV2 = async () => {
        const hasNoSignatures = !cachedImageSignature
            && !backendCachedImageSignature
            && !cachedHanddrawnSignature
            && !backendCachedHanddrawnSignature
            && !defaultHanddrawnSignature;

        /**
         * Check if there is a cached image signature (on backend or UI),
         * but image upload is not available and there is no handdrawn signature cached.
         */
        if (hasNoSignatures) {
            // No cached signatures available or image signature is available but cannot be used, open modal to choose signature
            openModalToChooseSignatureToSign();
        } else if (cachedImageSignature || backendCachedImageSignature) {
            // Image upload is available and there is a cached image signature
            // Use the backend cached image signature if it exists, otherwise use the UI cached image signature
            const image = cachedImageSignature || backendCachedImageSignature;
            const signatureDataChecksum = cachedSignatureData?.checksum || signatureData?.checksum;
            const imageChecksum = getChecksumFromBase64(image?.value);
            const isChecksumCorrect = imageChecksum === signatureDataChecksum;

            // if checksum is not equal to stored signature data,
            // that means it's chached uploaded image
            signUsingImage(image, !isChecksumCorrect);
        } else if (cachedHanddrawnSignature || backendCachedHanddrawnSignature) {
            // There is a cached handdrawn signature on the backend, use it
            signUsingSignature(cachedHanddrawnSignature || backendCachedHanddrawnSignature);
        } else {
            // No cached signatures available, sign with the default handdrawn signature
            signUsingSignature(defaultHanddrawnSignature);
        }
    };
    const signOnClick = async () => {
        const hasNoSignatures = !cachedImageSignature
            && !backendCachedImageSignature
            && !cachedHanddrawnSignature
            && !backendCachedHanddrawnSignature
            && !defaultHanddrawnSignature;
        /**
         * Check if there is a cached image signature (on backend or UI),
         * but image upload is not available and there is no handdrawn signature cached.
         */
        const hasImageSignatureButCannotUse = (backendCachedImageSignature || cachedImageSignature)
            && !canUseImageToSign
            && !backendCachedHanddrawnSignature
            && !cachedHanddrawnSignature;
        if (hasNoSignatures || hasImageSignatureButCannotUse) {
            // No cached signatures available or image signature is available but cannot be used, open modal to choose signature
            openModalToChooseSignatureToSign();
        } else if (canUseImageToSign && (backendCachedImageSignature || cachedImageSignature)) {
            // Image upload is available and there is a cached image signature
            // Use the backend cached image signature if it exists, otherwise use the UI cached image signature
            signUsingImage(backendCachedImageSignature || cachedImageSignature, true);
        } else if (backendCachedHanddrawnSignature) {
            // There is a cached handdrawn signature on the backend, use it
            signUsingSignature(backendCachedHanddrawnSignature);
        } else if (cachedHanddrawnSignature) {
            // There is a cached handdrawn signature on the UI, use it
            signUsingSignature(cachedHanddrawnSignature);
        } else {
            // No cached signatures available, sign with the default handdrawn signature
            signUsingSignature(defaultHanddrawnSignature);
        }
    };

    function onMoreBtnClick(e) {
        e.preventDefault();
        e.stopPropagation();
        openModalToChooseSignatureToSign();
    }

    return (
        <>
            <SignatureButton
                onClick={hasPerfectFreehandFeature ? signOnClickV2 : signOnClick}
                errorMessage={errorMessage}
                field={field}
                selectedField={selectedField}
                selectField={selectField}
                unselectField={unselectField}
                fieldsRef={fieldsRef}
                setFieldDataToRef={setFieldDataToRef}
                className={isOpenSignatureSelectionModal ? 'is-open-signature-selection' : null}
                loading={loading}
                data-wdio="test-capture-signature-field-btn"
            >
                {signatureMetaData?.signMessage}
                {!isDisabled && !loading && (
                    <CornerWidget width={position.width} height={position.height} data-wdio="test-signature-selection-widget-btn">
                        <Tooltip
                            placement="top"
                            title={joinMessage('sui.shared.select', 'sui.shared.signature')}
                            arrow
                        >
                            <AppOutlinedIconButton onClick={onMoreBtnClick}>
                                <Settings aria-hidden />
                            </AppOutlinedIconButton>
                        </Tooltip>
                    </CornerWidget>
                )}
            </SignatureButton>
            {hasPerfectFreehandFeature ? (
                <SignatureSelectionModalV2
                    isOpen={isOpenSignatureSelectionModal}
                    setIsOpen={setIsOpenSignatureSelectionModal}
                    signUsingImage={signUsingImage}
                    signUsingSignature={signUsingSignature}
                    field={field}
                    defaultHanddrawnSignature={defaultHanddrawnSignature}
                />
            ) : (
                <SignatureSelectionModal
                    isOpen={isOpenSignatureSelectionModal}
                    setIsOpen={setIsOpenSignatureSelectionModal}
                    signUsingImage={signUsingImage}
                    signUsingSignature={signUsingSignature}
                    field={field}
                    defaultSignature={defaultHanddrawnSignature}
                />
            )}
        </>
    );
};

export default CaptureSignature;
