import Button, {ButtonProps} from '@material-ui/core/Button';
import classNames from 'classnames';
import {forwardRef} from 'react';
import './appButton.less';

export type AppButtonProps = ButtonProps

// TODO to get rid of Button as a dependency needs to implement an animation on click and hover
export const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>(({
    className,
    children,
    variant,
    disabled,
    ...rest
}, ref) => {
    const containerClassName = classNames('app-button', className, {disabled});

    return (
        <Button
            ref={ref}
            className={containerClassName}
            disabled={disabled}
            {...rest}
        >
            {children}
        </Button>
    );
});
