import {forwardRef} from 'react';
import classnames from 'classnames';
import {IconButton, IconButtonProps} from '@material-ui/core';
import {HiOutlineDownload} from '@react-icons/all-files/hi/HiOutlineDownload';

export type AppDownloadIconButtonProps = Omit<IconButtonProps, 'classes'>

export const AppDownloadIconButton = forwardRef<HTMLButtonElement, AppDownloadIconButtonProps>(({
    className,
    disabled,
    size = 'small',
    ...restProps
}, ref) => {
    const containerClassName = classnames('download-icon-button', className, {disabled});

    return (
        <IconButton
            ref={ref}
            size={size}
            disabled={disabled}
            classes={{root: containerClassName}}
            {...restProps}
        >
            <HiOutlineDownload className="icon" aria-hidden />
        </IconButton>
    );
});
