export const VISIBILITY_ACCOUNT = 'ACCOUNT';
export const VISIBILITY_SENDER = 'SENDER';

export const OVERVIEW_TYPES = {
    ACCOUNT: 'account',
    USER: 'user'
};
export const OVERVIEW_TRANSACTIONS_TYPES = {
    ALL: 'all',
    MY: 'my'
};

export const TRANSACTION_STATUSES = {
    DECLINED: 'DECLINED',
    EXPIRED: 'EXPIRED',
    DRAFT: 'DRAFT',
    COMPLETED: 'COMPLETED',
    SENT: 'SENT',
    ARCHIVED: 'ARCHIVED'
};

export const ATTACHMENT_STATUSES = {
    INCOMPLETE: 'INCOMPLETE',
    COMPLETE: 'COMPLETE',
    REJECTED: 'REJECTED'
};

export const NEW_TRANSACTION_ACTIONS = {
    START_TRANSACTION: 'START_TRANSACTION',
    SIGN_DOCUMENT: 'SIGN_DOCUMENT'
};
export const NEW_TEMPLATE_ACTIONS = {
    START_TEMPLATE: 'START_TEMPLATE',
    SIGN_DOCUMENT: 'SIGN_DOCUMENT'
};

export const SENDER_ROLE = 'SENDER';
export const SIGNER_ROLE = 'SIGNER';

export const PACKAGE_QUERY_OPTIONS = {
    ALL: 'all',
    INBOX: 'inbox',
    DRAFT: 'draft',
    ARCHIVED: 'archived',
    TRASHED: 'trashed'
};

export const CONFIRMATION_MODAL_TYPES = {
    ARCHIVED: PACKAGE_QUERY_OPTIONS.ARCHIVED,
    TRASHED: PACKAGE_QUERY_OPTIONS.TRASHED,
    DELETE: 'delete'
};

export const PACKAGES_PER_PAGE_OPTIONS = {
    3: '3',
    5: '5',
    10: '10',
    20: '20'
};

export const PACKAGE_TYPES = {
    PACKAGE: 'PACKAGE',
    TEMPLATE: 'TEMPLATE'
};

export const PACKAGE_TYPE_NAME = {
    TEMPLATE: 'template',
    TRANSACTION: 'transaction'
};

export const PACKAGE_EXPIRY_DAYS = 90;
export const DEFAULT_FONT_SIZE = 12;
export const INHERIT_SIZE = null;
export const AUTO_FIT_SIZE = 0;
export const INHERIT_SIZE_KEY = 'inherit';
export const SUPPORTED_FONTSIZE = [AUTO_FIT_SIZE, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
export const FONT_SIZES = [INHERIT_SIZE_KEY, ...SUPPORTED_FONTSIZE];

export const PACKAGE_SORT_OPTIONS = {
    NAME: 'name',
    RECIPIENTS: 'recipients',
    CREATION: 'creation',
    COMPLETED: 'completed',
    DUE: 'due',
    STATUS: 'status',
    DOCUMENTS: 'documents',
    UPDATED: 'updated',
    VISIBILITY: 'visibility'
};

export const PACKAGE_DIR_OPTIONS = {
    DESC: 'desc',
    ASC: 'asc'
};

export const PACKAGE_PREDEFINED_OPTIONS = {
    ALL: 'all',
    SENT: 'sent',
    AWAITING_SIGNATURE: 'awaitingSignature',
    COMPLETED: 'completed',
    EXPIRING_SOON: 'expiringSoon',
    EXPIRED: 'expired',
    DECLINED: 'declined'
};

export const DEFAULT_TRANSACTIONS_OPTIONS = {
    from: 1,
    to: PACKAGES_PER_PAGE_OPTIONS[10],
    sort: PACKAGE_SORT_OPTIONS.UPDATED,
    dir: PACKAGE_DIR_OPTIONS.DESC,
    query: PACKAGE_QUERY_OPTIONS.INBOX,
    search: '',
    predefined: PACKAGE_PREDEFINED_OPTIONS.ALL
};

export const DEFAULT_TEMPLATES_OPTIONS = {
    from: 1,
    to: PACKAGES_PER_PAGE_OPTIONS[10],
    sort: PACKAGE_SORT_OPTIONS.UPDATED,
    dir: PACKAGE_DIR_OPTIONS.DESC,
    search: '',
    visibility: VISIBILITY_SENDER
};

export const RECIPIENT_TYPES = {
    SENDER: SENDER_ROLE,
    SIGNER: SIGNER_ROLE,
    PLACEHOLDER: 'PLACEHOLDER',
    GROUP: 'GROUP_SIGNER'
};

export const AUTH_TYPES = {
    NONE: 'NONE',
    SMS: 'SMS',
    CHALLENGE: 'CHALLENGE',
    SSO: 'SSO'
};

export const AUTH_TYPES_LABELS = {
    [AUTH_TYPES.NONE]: 'esl.generic.email',
    [AUTH_TYPES.SMS]: 'esl.generic.sms',
    [AUTH_TYPES.CHALLENGE]: 'esl.generic.qna',
    [AUTH_TYPES.SSO]: 'esl.generic.sso'
};

export const NEW_ROLE_ID_PLACEHOLDER = 'newRole';
export const LAYOUT_TYPE = 'LAYOUT';

export const LAYOUTS_SEARCH_TYPES = {
    EXACT_NAME: 'exactname'
};

export const TRANSACTION_SUMMARY_STATUSES = [
    'sent',
    'completed',
    'draft',
    'opted_out',
    'declined',
    'expired',
    'archived',
    'trashed'
];

export const MAX_FIRST_REMIND_AFTER = 365;
export const MAX_INTERVAL_REMINDER = 365;
export const MAX_REMINDERS = 5;
export const MIN_REMINDER_FIELD_VAL = 1;
export const DUE_DATE_DAYS_TYPE = 'days';
export const DUE_DATE_DATE_TYPE = 'date';
// MAX_DAYS is a backend limit for transaction expriartion date
export const MAX_DAYS = 966;
