/* eslint-disable no-unused-vars */
import {createSlice, PayloadAction, Slice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import httpClient from '../../utils/httpClient';
import {
    BASE_PATH,
    DEFAULT_LOCALE
} from '../../../constants';

export const SEVERITIES = {
    INFO: 'INFO',
    WARNING: 'WARNING',
    DANGER: 'DANGER',
    SUCCESS: 'SUCCESS'
} as const;

export type Severity = keyof typeof SEVERITIES;

export interface Notification {
    severity: Severity;
    title: string;
    content: string;
    iconKey?: string;
    actions?: {
        href: string;
        name: string;
        target: string;
    };
}

interface DashboardState {
    isSavingDataAttr: boolean;
    dashboardNotifications: Notification[];
    lastLoadedDashboardNotificationsLocale: string;
    isFetchingDashboardNotifications: boolean;
}

const initialState: DashboardState = {
    isSavingDataAttr: false,
    dashboardNotifications: [],
    lastLoadedDashboardNotificationsLocale: '',
    isFetchingDashboardNotifications: false
};

export const dashboardSlice: Slice<DashboardState> = createSlice({
    name: 'dashboardV2',
    initialState,
    reducers: {
        setIsSavingDataAttr: (state, action: PayloadAction<boolean>) => {
            state.isSavingDataAttr = action.payload;
        },
        setDashboardNotifications: (state, action: PayloadAction<Notification[]>) => {
            state.dashboardNotifications = action.payload;
        },
        setLastLoadedDashboardNotificationsLocale: (state, action: PayloadAction<string>) => {
            state.lastLoadedDashboardNotificationsLocale = action.payload;
        },
        setIsFetchingDashboardNotifications: (state, action: PayloadAction<boolean>) => {
            state.isFetchingDashboardNotifications = action.payload;
        }
    }
});

export const {
    setIsSavingDataAttr,
    setDashboardNotifications,
    setLastLoadedDashboardNotificationsLocale,
    setIsFetchingDashboardNotifications
} = dashboardSlice.actions;

export const saveDataAttr = (data: any) => async (dispatch: any, getState: any) => {
    const {user} = getState();
    const payload = {data};

    dispatch(setIsSavingDataAttr(true));

    try {
        await httpClient.post(`${BASE_PATH}/proxy/account/senders/${user.user.id}`, payload);
        dispatch(setIsSavingDataAttr(false));
        return true;
    } catch (err) {
        dispatch(setIsSavingDataAttr(false));
        return false;
    }
};

export const fetchDashboardNotifications = ({
    baseUrl,
    locale: fallbackLocale
}: {baseUrl?: string, locale?: string} = {}) => async (dispatch: any, getState: any) => {
    const {locale} = getState().app;
    const localeToFetch = fallbackLocale || locale;
    const servicePath = `api/custom/${localeToFetch}/dashboard.banner`;
    const url = baseUrl ? `${baseUrl}/${servicePath}` : `${BASE_PATH}/public/${servicePath}`;
    dispatch(setIsFetchingDashboardNotifications(true));

    try {
        const result = await httpClient.get(url);
        const dashboardNotifications = get(result, 'data', []);
        dispatch(setDashboardNotifications(dashboardNotifications));
        dispatch(setLastLoadedDashboardNotificationsLocale(localeToFetch));
        dispatch(setIsFetchingDashboardNotifications(false));
        return result.data;
    } catch (err) {
        dispatch(setIsFetchingDashboardNotifications(false));
        if (localeToFetch !== DEFAULT_LOCALE) {
            dispatch(fetchDashboardNotifications({locale: DEFAULT_LOCALE}));
        }
        return false;
    }
};

export const selectDashboardNotifications = (state: any) => state.dashboardV2.dashboardNotifications;
export const selectIsFetchingDashboardNotifications = (state: any) => state.dashboardV2.isFetchingDashboardNotifications;
export const selectLastLoadedDashboardNotificationsLocale = (state: any) => state.dashboardV2.lastLoadedDashboardNotificationsLocale;

export default dashboardSlice.reducer;
